<template>
  <div>
    <!--    <div class="title">-->
    <!--      <div>产品概览</div>-->
    <!--      <div class="bottom-line"></div>-->
    <!--    </div>-->
    <div class="data-charts">
      <home
        style="width: 100%;height: 100%"
        @findArea="findArea"
        @findIconColor="findIconColor"
        @clickItem="clickItem"/>
    </div>
    <div class="main-box" id="mainBox">
      <div class="title">
        <div>认证查询</div>
        <div class="bottom-line"></div>
      </div>
      <div class="search-box">
        <el-input
          placeholder="请输入关键字查询（产品名称/证书编号）"
          v-model="queryParam.searchValue"
          clearable>
          <el-button slot="append" icon="el-icon-search" @click="searchData">搜索</el-button>
        </el-input>
      </div>
      <div class="filter-box">
        <div class="clear-btn">
          <el-button size="mini" type="primary" @click="clearFilter">清空筛选条件</el-button>
        </div>
        <div>
          <span class="btn-bgc">选择地区</span>
          <el-cascader
            size="small"
            :options="options"
            v-model="queryParam.codeValue"
            clearable
            @change="handleChange"
            placeholder="请选择省市区"
          ></el-cascader>
        </div>
        <div>
          <span class="btn-bgc">认证类别</span>
          <el-select
            size="small"
            v-model="queryParam.typeValue"
            clearable
            placeholder="请选择认证类别"
            @change="getTableData(1)">
            <el-option
              v-for="item in productTypeData"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="table-box">
        <el-table
          v-loading="loading"
          :data="tableData"
          height="500"
          style="width: 100%;overflow-y: scroll">
          <el-table-column
            prop="productName"
            align="center"
            label="产品名称"
            width="180">
            <template v-slot="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.productName" placement="top">
                <span>{{ scope.row.productName }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="certificateNumber"
            align="center"
            label="证书编号"
            width="190">
          </el-table-column>
          <el-table-column
            prop="certificationCategory"
            align="center"
            label="认证类别"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="iconColor"
            align="center"
            label="证书标色"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="statusText"
            align="center"
            label="证书状态"
            width="150"
          >
            <template v-slot="scope">
              <span v-if="scope.row.certificateStatus=='0'">{{ scope.row.statusText }}</span>
              <span v-else style="color: red">{{ scope.row.statusText }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="companyName"
            align="center"
            width="180"
            label="申报主体">
          </el-table-column>
          <el-table-column
            prop="address"
            align="center"
            width="180"
            label="基地省/市">
          </el-table-column>
          <el-table-column
            prop="productionBaseName"
            align="center"
            width="200"
            label="基地名称">
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            :width="optionsWidth">
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.certificateStatus=='0'"
                @click="handleClick(scope.row)"
                type="text"
                size="small">查看获证产品
              </el-button>
              <el-link v-else type="danger" :underline="false">证书暂停无法查看获证产品</el-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-box">
        <p-pagination-base
          :currentPage.sync="pagination.currentPage"
          :pageSize.sync="pagination.pageSize"
          :page-sizes="[20, 30, 40]"
          :total="pagination.totalSize"
          v-show="!this.isEmpty"
          @size-change="handleSizeChange"
          @currentPageChange="changeCurrent"/>
      </div>
    </div>
  </div>
</template>

<script>

  import { regionData, CodeToText, provinceAndCityDataPlus } from 'element-china-area-data'
  import PPaginationBase from '@comp/project/pagination/PPaginationBase'
  import { productList } from '@/api/mock/homeIndex'
  import Home from '../../general/Home'
  import { queryCertificateSearch } from '../query/api/api'
  import { getAction } from '@/api/manage'
  import { mapState, mapActions } from 'vuex'


  export default {
    name: 'Query',
    components: { Home, PPaginationBase },
    data() {
      return {
        optionsWidth: 170,
        loading: false,
        queryParam: {
          searchValue: '',
          areaValue: '',
          codeValue: '',
          iconColor: '',
          typeValue: ''
        },
        productList: productList,
        pagination: {
          currentPage: 1,
          pageSize: 10,
          totalSize: 100
        },
        options: provinceAndCityDataPlus,
        CodeToText: CodeToText,
        iconColorOptions: [
          {
            value: '蓝标',
            label: '蓝标'
          },
          {
            value: '绿标',
            label: '绿标'
          },
          {
            value: '金标',
            label: '金标'
          }
        ],
        certificateStatus: [
          {
            value: '0',
            label: '正常'
          },
          {
            value: '1',
            label: '暂停'
          },
          {
            value: '2',
            label: '过期'
          },
          {
            value: '3',
            label: '吊销'
          }
        ],
        productTypeData: [
          {
            value: 'A',
            label: '工业消费品'
          },
          {
            value: 'B',
            label: '农食产品'
          },
          {
            value: 'C',
            label: '服务'
          },
          {
            value: 'D',
            label: '绿色建材'
          }
        ],
        tableData: []
      }
    },
    computed: {
      ...mapState('tabQuery', ['queryCache']),
      cachedQuery() {
        return this.queryCache[this.$route.path] || {}
      },
      isEmpty() {
        return this.tableData.length === 0
      }
    },
    created() {
      if (Object.keys(this.cachedQuery).length > 0) {
        this.queryParam = { ...this.cachedQuery }
        console.log(this.queryParam)
      }
      this.getTableData()
    },
    methods: {
      ...mapActions('tabQuery', ['saveQuery', 'clearQuery']),
      handleChange(value) {
        console.log(value)// 打印出城市code值
        this.getCodeToText(value) // 转换城市名称方法
      },
      // 转换城市名称方法
      getCodeToText(val) {
        let cityName = ''
        switch (val.length) {
        case 1:
          cityName += CodeToText[val[0]]
          break
        case 2:
          cityName += CodeToText[val[0]] + '/' + CodeToText[val[1]]
          break
        case 3:
          cityName +=
            CodeToText[val[0]] +
            CodeToText[val[1]] +
            CodeToText[val[2]]
          break
        default:
          break
        }
        // 剔除字符串中包含“全部”的部分
        cityName = cityName.replace('全部', '')
        console.log(cityName)
        this.queryParam.areaValue = cityName
        this.getTableData(1)
      },
      changeCurrent(page) {
        this.pagination.currentPage = page
        this.getTableData()
      },
      handleSizeChange(val) {
        this.pagination.pageSize = val
        this.getTableData()
      },
      getTableData(arg) {
        if (window.innerWidth >= 768) {
          this.optionsWidth = 170
        } else {
          this.optionsWidth = 150
        }
        if (arg === 1) {
          this.pagination.currentPage = 1
          this.pagination.pageSize = 10
        }
        // 上一次的查询条件
        let params = {
          queryPage: this.pagination.currentPage,
          querySize: this.pagination.pageSize,
          certificationCategory: this.queryParam.typeValue,
          iconColor: this.queryParam.iconColor,
          keyword: this.queryParam.searchValue,
          province: this.queryParam.areaValue.split('/')[0],
          city: this.queryParam.areaValue.split('/')[1]
        }
        let fun = this.$OpenCC.Converter({ from: 'tw', to: 'cn' })
        if (params.keyword) {
          // 将繁体文字转化成简体请求接口
          params.keyword = fun(params.keyword)
        }
        this.saveQuery({
          path: this.$route.path,
          query: this.queryParam
        })
        this.loading = true
        let act = queryCertificateSearch(params)
        // let _act = getAction('api/v1/dms/certificate/certificateSearch', params)
        act.then(res => {
          this.tableData = res.list.map(item => {
            return {
              ...item,
              address: item.province + item.city,
              certificateStatus: item.certificateStatus || '0',
              statusText: item.certificateStatus ? this.certificateStatus.filter(i => i.value === item.certificateStatus)[0].label : '正常',
              certificationCategory: this.productTypeData.filter(i => i.value === item.certificationCategory)[0].label,
              iconColor: item.iconColor ? this.iconColorOptions.filter(i => i.value === item.iconColor)[0].value : '-'
            }
          })
          this.pagination.totalSize = res.totalSize
          this.loading = false
        }).finally(() => {
          this.loading = false
          this.$nextTick(() => {
            this.$zh_tran(localStorage.getItem('lang'))
          })
        })
      },
      searchData() {
        this.getTableData(1)
      },
      scrollToDiv(hashId) {
        // 遍历元素跳转
        window.document.querySelector(hashId).scrollIntoView({ behavior: 'smooth', block: 'start' })
      },
      getAreaCodeByName(name) {
        let result = {
          fullCode: null
        }
        // 第一层查找：省级
        for (const province of regionData) {
          if (province.label === name) {
            result.fullCode = [province.value, '']
            return result.fullCode
          }
          // 第二层查找：市级
          if (province.children) {
            for (const city of province.children) {
              if (city.label === name) {
                result.fullCode = [province.value, city.value]
                return result.fullCode
              }
            }
          }
        }
        return result.fullCode
      },
      findArea(val) {
        // 滚动到指定dom位置
        this.scrollToDiv('#mainBox')
        this.queryParam.areaValue = val
        // 根据地区名字（val）推断出地区code值
        this.queryParam.codeValue = this.getAreaCodeByName(val)
        console.log(this.queryParam.codeValue)
        this.queryParam.iconColor = ''
        this.queryParam.typeValue = ''
        this.getTableData(1)
      },
      clickItem(val) {
        // 滚动到指定dom位置
        this.scrollToDiv('#mainBox')
        this.queryParam.areaValue = '/' + val
        this.queryParam.codeValue = this.getAreaCodeByName(val)
        this.queryParam.typeValue = ''
        this.queryParam.iconColor = ''
        this.getTableData(1)
      },
      findIconColor(val) {
        // 滚动到指定dom位置
        this.scrollToDiv('#mainBox')
        this.queryParam.areaValue = ''
        this.queryParam.typeValue = ''
        this.queryParam.codeValue = '0'
        this.queryParam.iconColor = this.iconColorOptions.filter(i => i.label === val)[0].label
        this.getTableData(1)
      },
      clearFilter() {
        this.queryParam.searchValue = ''
        this.queryParam.areaValue = ''
        this.queryParam.typeValue = ''
        this.queryParam.iconColor = ''
        this.queryParam.codeValue = '0'
        this.clearQuery(this.$route.path)
        this.getTableData(1)
      },
      handleClick(data) {
        console.log(data)
        this.$router.push({
          path: '/bayareacertification/queryDetail',
          query: {
            id: data.id
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
@media (min-width: 768px) {
  .main-box {
    width: 100%;
    background: url("~@/assets/index/query/query-bg.png") no-repeat;
    background-size: 100% 100%;
  }

  .title {
    padding: 33px 0;
    font-size: 24px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .bottom-line {
      width: 30px;
      margin-top: 8px;
      border-bottom: 3px solid @primary-color;
    }
  }

  // 可视化布局
  .data-charts {
    width: 100%;
    height: 700px;
    background-color: #E8EAF4;
    margin-top: 85px;
  }

  .search-box {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }

  .table-box {
    width: 1200px;
    margin: 0 auto;
  }

  .filter-box {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 1200px;
    margin: 20px auto;
    height: 80px;

    .btn-bgc {
      display: inline-block;
      width: 89px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 4px;
      background-color: @primary-color;
      color: rgba(250, 250, 250, 1);
      font-size: 16px;
      margin-right: 20px;
    }
  }

  .clear-btn {
    position: absolute;
    right: 0;
    top: 0;
  }

  .pagination-box {
    text-align: center;
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .main-box {
    width: 100%;
    background: url("~@/assets/index/query/query-bg.png") no-repeat;
    background-size: 100% 100%;
  }

  .title {
    padding: 33px 0;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .bottom-line {
      width: 30px;
      margin-top: 8px;
      border-bottom: 3px solid @primary-color;
    }
  }

  // 可视化布局
  .data-charts {
    width: 100%;
    height: 100%;
    background-color: #E8EAF4;
    margin-top: 85px;
  }

  .search-box {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 0 10px;
  }

  .table-box {
    width: 100%;
    margin: 0 auto;
  }

  .filter-box {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin: 20px auto;
    height: 80px;
    padding: 0 10px;

    .btn-bgc {
      display: inline-block;
      width: 89px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 4px;
      background-color: @primary-color;
      color: rgba(250, 250, 250, 1);
      font-size: 14px;
      margin-right: 20px;
    }
  }

  .clear-btn {
    position: absolute;
    right: 10px;
    top: 0;
  }

  .pagination-box {
    text-align: center;
    margin-bottom: 30px;
  }

  /deep/ .el-pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  /deep/ .el-pagination__jump {
  }
}

/deep/ .el-table {
  font-size: 16px !important;
}

/deep/ .el-button--small {
  font-size: 16px;
}

/deep/ .el-input-group__append button.el-button {
  border-color: @primary-color;
  background-color: @primary-color;
  color: #ffffff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top: 1px solid @primary-color;
}

/deep/ .el-button--text {
  color: @primary-color;
}

/deep/ .el-input {
  font-size: 16px;
}

/deep/ .el-input__inner {
  height: auto;
}

/deep/ .el-input__icon {
  font-size: 16px;
}

/deep/ .el-select .el-input .el-select__caret {
  font-size: 16px;
}

/deep/ .el-table .cell {
  display: -webkit-box; /* 设置为WebKit内核的弹性盒子模型 */
  -webkit-box-orient: vertical; /* 垂直排列 */
  -webkit-line-clamp: 3; /* 限制显示两行 */

}

/deep/ .el-button--primary {
  background-color: @primary-color;
  border-color: @primary-color;
  color: #ffffff;

  &:hover {
    opacity: 0.85;
  }
}
</style>
